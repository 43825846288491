import { useState } from "react";
import { isNotEmpty, toAbsoluteUrl } from "../../../../_metronic/helpers";
import clsx from "clsx";
import axios from "axios";
import { toast } from "react-toastify";



const VehicleSwapModalForm = ({
  vehicles,
  selectedVehicle,
  setSelectedVehicle,
  fetchBooking,
  selectedBookingId,
  fetchBookingId,
  bookingDetails,
  setSelectSwapVehicle,
  selectSwapVehicle
}) => {

  const vehicleTypeTruck = bookingDetails.prices?.find((item) => item.vehicle_type == 1)?.vehicle_type
  const vehicleTypeTrailer = bookingDetails.prices?.find((item) => item.vehicle_type == 2)?.vehicle_type
  const vehicleTypeTruckTrailer = bookingDetails.prices?.find((item) => item.vehicle_type == 3)?.vehicle_type


  const onSwapVehicle = (vehicle) => {
    axios.delete(`${process.env.REACT_APP_API_V2_URL}/booking/${selectedBookingId}/vehicle?vehicle_id=${selectedVehicle.vehicle_id}`).then((res) => {
      onAddVehicle(vehicle)
    }).catch((err) => {
      toast.error(err.response.data.message);
    }) 
  }


  const onRemoveVehicle = (vehicle) => {
  };

  const onAddVehicle = (vehicle) => {
    axios.post(`${process.env.REACT_APP_API_V2_URL}/booking/${selectedBookingId}/vehicle`, {
      vehicle_id: vehicle
    }).then((res) => {
      toast.success("Vehicle Changed Successfully");
      fetchBooking();
      fetchBookingId(selectedBookingId);
      setSelectedVehicle(null);
    }).catch((err) => {
      toast.error(err.response.data.message);
    })
  };

const filterVehiclesWithPrice = (vehicles) => {
  let tempVehicles=[]
  if(selectedVehicle.vehicle_type == vehicleTypeTruck){
    tempVehicles = vehicles.filter((item) => item.vehicle_type == 1)
  }else if(selectedVehicle.vehicle_type == vehicleTypeTrailer){
    tempVehicles = vehicles.filter((item) => item.vehicle_type == 2)
  }else if(selectedVehicle.vehicle_type == vehicleTypeTruckTrailer){
    tempVehicles = vehicles.filter((item) => item.vehicle_type == 3)
  }else{
    tempVehicles = vehicles;
  }
  return tempVehicles
}

const vehicleType = (type) => {
  switch (type) {
    case 1:
      return 'Truck';
    case 2:
      return 'Trailer';
    case 3:
      return 'Truck Trailer';
      
     default:
      return '' 
    
  }
}



  return (
    <>
      {/* begin::Scroll */}
      <div
        className="d-flex flex-column me-n7 pe-7"
        style={{ height: 'auto', maxHeight: '270px' }}
        id="kt_modal_add_user_scroll"
        data-kt-scroll="true"
        data-kt-scroll-activate="{default: false, lg: true}"
        data-kt-scroll-max-height="auto"
        data-kt-scroll-dependencies="#kt_modal_add_user_header"
        data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
        data-kt-scroll-offset="300px"
      >
        <div>
          {filterVehiclesWithPrice(vehicles)?.length > 0 ? filterVehiclesWithPrice(vehicles)?.map((item) => (
            <div
              className="vehicle-card"
              style={{
                borderRadius: "10px",
                border: selectSwapVehicle?.id == item.id ? "2px solid #ffa459" : "1px solid #b2b2b2",
                padding: "10px",
                background: "#f5f5f5",
                marginBottom: "10px",
                display: "flex",
                gap: "10px",
                flexWrap: "wrap",
                position: "relative",
                marginBottom: "15px",
                cursor: "pointer"
              }}
              onClick={() => {
                if(selectSwapVehicle?.id == item.id){
                  setSelectSwapVehicle(null)
                }else{
                  setSelectSwapVehicle(item)
                }
              }}
            >
              <span
                style={{
                  position: "absolute",
                  background: selectSwapVehicle?.id == item.id ? "#ffa459" : "#b2b2b2",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  right: "-10px",
                  top: "-10px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setSelectSwapVehicle(item)
                }}
              >
                 <i
                  className="fas fa-check"
                  style={{ color: "#fff" }}
                />
              </span>
              <div className="vehicle-label">
                <h6 style={{ color: "#a3a3a3" }}>
                  VEHICLE ID
                </h6>
                <h5 style={{ color: "#4a4a4a" }}>
                  {item?.id || "-"}
                </h5>
              </div>
              <div className="vehicle-label">
                <h6 style={{ color: "#a3a3a3" }}>
                  VEHICLE TYPE
                </h6>
                <h5 style={{ color: "#4a4a4a" }}>
                  {+item?.vehicle_type === 1
                    ? "TRUCK"
                    : +item?.vehicle_type === 2
                      ? "TRAILER"
                      : "TRUCK + TRAILER"
                  }
                </h5>
              </div>
              {+item.vehicle_type !== 2 && (
                <div className="vehicle-label">
                  <h6 style={{ color: "#a3a3a3" }}>
                    COMPANY NAME
                  </h6>
                  <h5 style={{ color: "#4a4a4a" }}>
                    {item?.name || "-"}
                  </h5>
                </div>
              )}
              {+item.vehicle_type !== 2 && (
                <div className="vehicle-label">
                  <h6 style={{ color: "#a3a3a3" }}>
                    TRUCK #
                  </h6>
                  <h5 style={{ color: "#4a4a4a" }}>
                    {item?.truck_number || "-"}
                  </h5>
                </div>
              )}
              {+item.vehicle_type !== 2 && (
                <div className="vehicle-label">
                  <h6 style={{ color: "#a3a3a3" }}>
                    TRUCK MAKE
                  </h6>
                  <h5 style={{ color: "#4a4a4a" }}>
                    {item?.make || "-"}
                  </h5>
                </div>
              )}
              {+item.vehicle_type !== 2 && (
                <div className="vehicle-label">
                  <h6
                    style={{
                      color: "#a3a3a3",
                      textTransform: "uppercase"
                    }}
                  >
                    TRUCK PLATE #
                  </h6>
                  <h5 style={{ color: "#4a4a4a" }}>
                    {item?.plate_number || "-"}
                  </h5>
                </div>
              )}
              {+item.vehicle_type !== 1 && (
                <div className="vehicle-label">
                  <h6 style={{ color: "#a3a3a3" }}>
                    TRAILER TYPE
                  </h6>
                  <h5 style={{ color: "#4a4a4a" }}>
                    {item.vehicle_type !== 1
                      ? item?.model || "-"
                      : "Not Available"
                    }
                  </h5>
                </div>
              )}
              {+item.vehicle_type !== 1 && (
                <div className="vehicle-label">
                  <h6 style={{ color: "#a3a3a3" }}>
                    TRAILER #
                  </h6>
                  <h5
                    style={{
                      color: "#4a4a4a",
                      textTransform: "uppercase"
                    }}
                  >
                    {item?.trailer_number || "-"}
                  </h5>
                </div>
              )}
              {+item.vehicle_type !== 1 && (   
                 <div className="vehicle-label">
                  <h6 style={{ color: "#a3a3a3" }}>
                    {+item.vehicle_type === 3 && "TRAILER"} COMPANY NAME
                  </h6>
                  <h5
                    style={{
                      color: "#4a4a4a",
                      textTransform: "uppercase"
                    }}
                  >
                    {+item.vehicle_type === 2 ? item?.name : item?.company_name || "-"}
                  </h5>
                </div>
                )}
              {+item.vehicle_type !== 1 && (
                <div className="vehicle-label">
                  <h6 style={{ color: "#a3a3a3" }}>
                    TRAILER PLATE #
                  </h6>
                  <h5
                    style={{
                      color: "#4a4a4a",
                      textTransform: "uppercase"
                    }}
                  >
                    {item?.trailer_plate || "-"}
                  </h5>
                </div>
              )}
                 <div className="vehicle-label">
                  <h6 style={{ color: "#a3a3a3" }}>
                    VIN #
                  </h6>
                  <h5
                    style={{
                      color: "#4a4a4a",
                      textTransform: "uppercase"
                    }}
                  >
                    {item?.vin_number || "-"}
                  </h5>
                </div>
            </div>
          ))
        : 
        <div className="vehicle-label w-100">
          <h6 style={{ color: "#a3a3a3"}}>
            No other {vehicleType(selectedVehicle?.vehicle_type)} vehicles found
          </h6>
        </div>
        }
        </div>
      </div>
    </>
  );
};

export { VehicleSwapModalForm };
