import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

const ApprovalModalForm = ({
  image,
  setImage,
  fetchImageApprovals,
  setOpenApprovalModal
}) => {
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false)

  const cancel = () => {
    setImage(null);
    setOpenApprovalModal(false);
  };

  const pendingApproval = (type, id, reason) => {
    if (type) {
      setLoading(true)
      axios.patch(`${process.env.REACT_APP_API_V3_URL}/listing/images/approve?asset_id=${id}`).then(res => {
        toast.success("Image Approved Successfully");
        fetchImageApprovals()
        setLoading(false)
        setOpenApprovalModal(false)
      }).catch(err => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message)
          setLoading(false)
        }
      })
    } else {
      setLoading(true)
      axios.patch(`${process.env.REACT_APP_API_V3_URL}/listing/images/stash?asset_id=${id}&reject_reason=${reason}`).then(res => {
        toast.success("Image Rejected Successfully");
        fetchImageApprovals()
        setLoading(false)
        setOpenApprovalModal(false)
      }).catch(err => {
        if (err && err.response && err.response?.data && err.response?.data?.message) {
          toast.error(err.response.data.message)
          setLoading(false)
        }
      })
    }
  };

  return (
    <>
      <div className="kt_body">
          <>
            {image.type === "accept" ? (
              <div>
                <h4>
                  Are you sure you want to{" "}
                  <span style={{ color: "#2ecc71", fontWeight: 700 }}>
                    APPROVE
                  </span>{" "}
                  the Image for this listing
                </h4>
                <div className="text-center pt-5">
                  <button
                    type="reset"
                    onClick={() => cancel()}
                    className="btn btn-light me-3"
                    data-kt-users-modal-action="cancel"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    id="approve"
                    style={{ backgroundColor: "#2ecc71", color: "#fff" }}
                    data-kt-users-modal-action="submit"
                    disabled={loading}
                    onClick={() => {
                      pendingApproval(true, image.asset_id);
                    }}
                  >
                    <span className="indicator-label">APPROVE IMAGE</span>
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <h4>
                  Are you sure you want to{" "}
                  <span style={{ color: "#ed2626", fontWeight: 700 }}>
                    REJECT
                  </span>{" "}
                  the Image for this listing
                </h4>
                <textarea
                    className="form-control form-control-solid w-100 mt-4"
                    placeholder="Reject Reason (Min: 5 Characters & Max: 255 Characters)"
                    value={reason}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length <= 255) {
                        setReason(value);
                      }
                    }}
              />
                <div className="text-center pt-5">
                  <button
                    type="reset"
                    onClick={() => cancel()}
                    className="btn btn-light me-3"
                    data-kt-users-modal-action="cancel"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="btn"
                    style={{ backgroundColor: "#ed2626", color: "#fff" }}
                    data-kt-users-modal-action="submit"
                    disabled={(!reason || reason.length < 5 || reason.length > 255) || loading}
                    onClick={() => {
                      pendingApproval(false, image.asset_id, reason);
                    }}
                  >
                    <span className="indicator-label">REJECT IMAGE</span>
                  </button>
                </div>
              </div>
            )}
          </>
      </div>
    </>
  );
};

export { ApprovalModalForm };
