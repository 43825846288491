import { UsersListLoading } from "../../Reviews/Review/users-list/components/loading/UsersListLoading";
import { KTCardBody } from "../../../../_metronic/helpers";
import { Pagination } from "../../../../components/Pagination";
import { formatPrice } from "../../../../_metronic/helpers/utils";
import { Link } from "react-router-dom";

const headers = [
  { title: 'Customer Name', width: '150px' },
  { title: 'Member Number', width: '150px' },
  { title: 'Organization Name', width: '170px' },
  { title: 'Available Funds', width: '150px' },
  { title: 'Total Deposited Funds', width: '150px' },
]



export const WalletInfoTable = ({ walletData, isLoading, page, setPage, pageSize, setPageSize }) => {

  return (
    <KTCardBody className='py-4'>
      <h6 style={{color:'#7e8299', textAlign:'right'}}>Total: {walletData?.total || 0} </h6>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
        >
          <thead>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            {headers.map(column => (
              <th
                key={column.title}
                className={column.className}
                style={{ minWidth: column.width }}
              >
                {column.title}
              </th>
            ))}
          </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
          {walletData?.data?.length > 0 && (
            walletData?.data.map(row => (
              <tr key={row.arrear_id}>
                <td style={{ minWidth: headers[0].width }}>
                <Link to={`/users/${row.member_uid}`}>
                  {row.member_name || '-'}
                </Link>
                  </td>
                <td style={{ minWidth: headers[1].width }}>
                <Link to={`/users/${row.member_uid}`}>
                  {row.member_id || '-'}
                </Link> 
                  </td>
                <td style={{ minWidth: headers[2].width }}>
                <Link to={`/organizations?search=${row.org_name}`}>
                {row.org_name || '-'}
                </Link>
                </td>
                <td style={{ minWidth: headers[4].width, color: row.unallocated_balance <= 0 ? '#ed2626' : '#2ecc71' }}>{formatPrice(row.unallocated_balance || 0) || '$0'}</td>
                <td style={{ minWidth: headers[3].width, color: row.lifetime_cash <= 0 ? '#ed2626' : '#2ecc71' }}>{formatPrice(row.lifetime_cash) || '$0'}</td>
              </tr>
            ))
          )}
          </tbody>
        </table>
        {walletData?.data && !walletData?.data?.length > 0 && 
        <div className='d-flex text-center text-gray-600 fw-bolder fs-6 w-100 align-content-center justify-content-center mt-10'>
         No matching records found
        </div>
        }
      </div>
      <Pagination
        page={page}
        setPage={setPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        total={walletData?.total || 0}
      />
      {isLoading && <UsersListLoading />}
    </KTCardBody>
  );
};
